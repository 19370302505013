import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({ title, description, url }) => {
  return (
    <Helmet>
      <title>{title}</title>

      {/* Standard Meta Tags */}
      <meta name="description" content={description} />

      {/* OpenGraph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://bocaenboca.app/social.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content="https://bocaenboca.app/social.jpg"
      />
    </Helmet>
  );
};

export default MetaTags;
