import { FaChevronRight } from "react-icons/fa6";
import logo from "../assets/logoBig.png";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { Link, useNavigate, useParams } from "react-router-dom";
import Term from "./Term";
import Privacy from "./Privacy";
import Refund from "./Refund";

const TermAndConditionMobile = () => {
  const { name } = useParams();
  const [termsContent, setTermsContent] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://bocaenboca.goodcoderz.com/api/v1/admin/business-settings/get-pages-setup?page_name=${name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setTermsContent(response.data.content.value);
      } catch (error) {
        console.error("Error fetching terms:", error);
        setLoading(false);
      }
    };

    fetchTermsContent();
  }, []);

  const formatPolicyName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const renderComponent = () => {
    switch (name) {
      case "terms_&_conditions":
        return <Term />;
      case "privacy_policy":
        return <Privacy />;
      case "cancellation_&_refund_policy":
        return <Refund />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className="bg-white min-h-screen flex flex-col justify-between ">
      <main className="mx-auto w-full   pb-16 pt-5 sm:pb-24">
        <img
          className="mx-auto h-14 w-auto md:h-16 lg:h-20"
          src={logo}
          alt="Your Company"
        />
        <div className="mx-auto mt-10 max-w-4xl text-center">
          <h1 className="mt-4 text-2xl font-extrabold tracking-tight text-neutral-700 sm:text-4xl">
            {formatPolicyName(name)}
          </h1>

          {loading ? (
            <div className="flex flex-col w-full gap-5 mt-5 text-center justify-center items-center">
              <p className="w-full h-4 rounded-full bg-neutral-300 animate-pulse"></p>
              <p className="w-11/12 h-4 rounded-full bg-neutral-300 animate-pulse"></p>
              <p className="w-9/12 h-4 rounded-full bg-neutral-300 animate-pulse"></p>
              <p className="w-11/12 h-4 rounded-full bg-neutral-300 animate-pulse"></p>
              <p className="w-8/12 h-4 rounded-full bg-neutral-300 animate-pulse"></p>
            </div>
          ) : (
            <div className="mt-6 w-full text-left text-base leading-7 text-neutral-700 sm:text-lg sm:leading-8">
              {renderComponent()}
              {/* <div dangerouslySetInnerHTML={{ __html: termsContent }} /> */}
            </div>
          )}
        </div>
      </main>

      <footer className="bg-white border-t border-neutral-200 py-4 fixed w-full bottom-0">
        <div className="text-center text-xs text-neutral-500">
          <p>
            Boca En Boca is a trademark of The Peddler Group, Inc. Copyright
            2022.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default TermAndConditionMobile;
