import React, { useState, useEffect } from "react";
import RadioRole from "../components/mini-components/RadioRole";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import LeftAlignedAuthFormHeading from "../components/mini-components/LeftAlignedAuthFormHeading";
import { useNavigate } from "react-router-dom";
import TermAndConditionCheckbox from "../components/mini-components/TermAndConditionCheckbox";
import { Toaster, toast } from "sonner";
// import { plans } from "../data/plans";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import axios from "axios";
import { PiEyeClosed } from "react-icons/pi";
import { MdRemoveRedEye } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import HampsterLoader from "../components/loading/HampsterLoader";
import StateInput from "./StateInput";
import CityInput from "./CityInput";
import { useDispatch, useSelector } from "react-redux";
import MainInputComponent from "./MainInputComponent";
import translations from "../languages";
import { Link } from "react-router-dom";
import AuthFormHeading from "../components/mini-components/AuthFormHeading";
const SignUp = () => {
  const navigate = useNavigate();

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [enabledTandC, setEnabledTandC] = useState(true);
  const [selected, setSelected] = useState("");
  const selectedLanguage = useSelector((state) => state.language.language);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const texts = translations[selectedLanguage].signUpPage;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailPhone: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    username: "",
    state: "",
    city: "",
    country_id: "233",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailPhone: "",
    password: "",
    confirmPassword: "",
    username: "",
    state: "",
    city: "",
    type: "",
  });

  const [loading, setLoading] = useState(false);
  // src/data/plans.js
  const plans = [texts.jobSeeker, texts.employer];

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/states/233`
      );
      if (response.data.response_code === "default_200") {
        setStates(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
    setLoading(false);
  };

  const fetchCities = async (stateId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/cities/${stateId}`
      );
      if (response.data.response_code === "default_200") {
        setCities(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
    setLoading(false);
  };

  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    setFormData({ ...formData, state: stateId });
    fetchCities(stateId);
  };

  const handleCityChange = (cityId) => {
    setFormData({ ...formData, city: cityId });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else if (type === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s'-]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9]{3,15}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,35}$/;

    const newErrors = {
      firstName: !formData.firstName
        ? "First Name is required"
        : formData.firstName.length > 30
        ? "First Name should not exceed 30 characters"
        : !nameRegex.test(formData.firstName)
        ? "First Name should contain only alphabetic characters, spaces, apostrophes, and hyphens"
        : "",
      lastName: !formData.lastName
        ? "Last Name is required"
        : formData.lastName.length > 30
        ? "Last Name should not exceed 30 characters"
        : !nameRegex.test(formData.lastName)
        ? "Last Name should contain only alphabetic characters, spaces, apostrophes, and hyphens"
        : "",
      emailPhone: !formData.emailPhone
        ? "Email is required"
        : !emailRegex.test(formData.emailPhone)
        ? "Invalid email format"
        : "",
      username: !formData.username
        ? "Username is required"
        : !usernameRegex.test(formData.username)
        ? "Username should only contain alphabetic characters and numbers, and be between 3 and 15 characters long"
        : "",
      password: !formData.password
        ? "Password is required"
        : formData.password.length < 8
        ? "Password is too weak."
        : !passwordRegex.test(formData.password)
        ? "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        : "",
      state: !formData.state ? "State is required" : "",
      city: !formData.city ? "City is required" : "",
      type: !selected ? "Type is required" : "",
      confirmPassword:
        formData.confirmPassword !== formData.password
          ? "Passwords do not match"
          : "",
    };
    setErrors(newErrors);
    const isValid = !Object.values(newErrors).some((error) => error);
    return isValid;
  };
  const handleSignUp = async () => {
    if (!validateForm()) {
      toast("Please correct the errors in the form", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      return;
    }
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.emailPhone,
      password: formData.password,
      phone: formData.phoneNumber,
      username: formData.username,
      type:
        selected === "Job Seeker"
          ? "job-seeker"
          : selected.toLowerCase().replace(" ", "-"),
      country_id: formData.country_id,
      state_id: String(formData.state), // Ensuring state is a string
      city_id: String(formData.city), // Ensuring city is a string
    };

    try {
      setLoadingLogin(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/auth/registration`,
        payload
      );
      if (response.status === 200) {
        localStorage.setItem(
          "identity",
          JSON.stringify({
            identity: formData.emailPhone,
            identity_type: "email",
          })
        );
        navigate("/otp");
        setLoadingLogin(false);
        toast("Registration Success", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } else {
        setLoadingLogin(false);
        toast("Registration failed", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      setLoadingLogin(false);
      toast("Registration failed:", error, {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSignUp();
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden bg-white overflow-y-auto">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-transparent bg-opacity-50 z-50">
          <HampsterLoader />
        </div>
      )}
      <div className="bg-neutral-50 lg:w-4/12 md:w-8/12 w-11/12 rounded-[2rem] px-10 py-2 shadow-xl flex text-center flex-col mt-3  max-h-[130%]">
        <div className="flex w-full">
          <IoArrowBackCircleOutline
            className="text-3xl text-black  justify-start items-start text-left cursor-pointer hover:scale-110  relative top-6"
            onClick={() => navigate("/login")}
          />
        </div>
        <AuthFormHeading heading={texts.welcome} />

        <div className="flex lg:flex-row md:flex-col space-x-3 w-full">
          <div className="flex gap-3 w-full">
            <MainInputComponent
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              error={errors.firstName}
              placeholder={texts.firstName}
            />

            <MainInputComponent
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              error={errors.lastName}
              placeholder={texts.lastName}
              className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
            />
          </div>
        </div>

        <MainInputComponent
          type="text"
          name="emailPhone"
          value={formData.emailPhone}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={texts.email}
          error={errors.emailPhone}
          className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
        />

        <div className="flex gap-3 w-full">
          <MainInputComponent
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            error={errors.username}
            placeholder={texts.username}
            className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
          />

          <MainInputComponent
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder={texts.telephone}
            className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
          />
        </div>

        <div className="flex gap-3">
          <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={texts.password}
              className="w-10/12 h-full p-3 bg-transparent outline-none text-sm"
            />
            {showPassword ? (
              <MdRemoveRedEye
                className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("password")}
              />
            ) : (
              <PiEyeClosed
                className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("password")}
              />
            )}
          </div>

          <div className="bg-[#F2F2F2]  rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full flex justify-between items-center px-2">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={texts.confirmPassword}
              className="w-10/12 h-full p-3 bg-transparent outline-none text-sm"
            />
            {showConfirmPassword ? (
              <MdRemoveRedEye
                className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            ) : (
              <PiEyeClosed
                className="text-neutral-700 text-2xl justify-center items-center cursor-pointer"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            )}
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col space-x-20 w-full">
          {errors.password && (
            <p className="text-[#E7831D] text-xs mt-1 text-left ml-5">
              {errors.password}
            </p>
          )}
          {errors.confirmPassword && (
            <p className="text-[#E7831D] text-xs mt-1 text-left ml-5">
              {errors.confirmPassword}
            </p>
          )}
        </div>

        <div className="flex lg:flex-row md:flex-col space-x-2 w-full">
          <StateInput
            value={formData.state}
            onChangestate={handleStateChange}
            option={states}
            error={errors.state}
            name={texts.selectState}
          />

          <CityInput
            value={formData.city}
            onChangecity={handleCityChange}
            option={cities}
            error={errors.city}
            name={texts.selectCity}
          />
        </div>

        <div className="flex lg:flex-row md:flex-col space-x-2 w-full"></div>
        <RadioRole
          selected={selected}
          setSelected={setSelected}
          plans={plans}
          jobseeker={texts.jobSeeker}
          employer={texts.employer}
        />

        {errors.type && (
          <p className="text-[#E7831D] text-xs mt-1 text-left ml-5">
            {errors.type}
          </p>
        )}

        <TermAndConditionCheckbox
          enabledTandC={enabledTandC}
          setEnabledTandC={setEnabledTandC}
          texts={texts.agreeTerms}
        />

        <button
          className="bg-[#404041] h-12 font-bold rounded-full  mt-3 text-white  hover:bg-neutral-500 hover:scale-95 transition-transform ease-in-out duration-300 mb-3"
          onClick={handleSignUp}
        >
          {loadingLogin && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}

          {texts.signUp}
        </button>
      </div>

      <p className="  h-10 fixed bottom-0  mt-3 flex justify-center items-center  space-x-2  w-full   z-50 text-md text-neutral-400 text-[.6rem]">
        {" "}
        <span
          className="  cursor-pointer"
          onClick={() => navigate("/policy/terms_&_conditions")}
        >
          {" "}
          Terms & Conditions{" "}
        </span>{" "}
        <span
          className=" cursor-pointer "
          onClick={() => navigate("/policy/privacy_policy")}
        >
          {" "}
          Privacy Policy{" "}
        </span>{" "}
        <Link to="/set/policy/cancellation_&_refund_policy" className="ml-1">
          Cancellation & Refund Policy
        </Link>
      </p>
    </div>
  );
};

export default SignUp;
