import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Button, Modal } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import card from "../../assets/mastercardlogo.png";
import { CiCreditCard1 } from "react-icons/ci";
import BigLogo from "../../assets/mainlogonew.png";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import { IoClose } from "react-icons/io5";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import PaymentModal from "./PaymentModal";
import axios from "axios";
import SubmitModal from "./SubmitModal";
import SubscriptionCard from "./SubscriptionCard";
import CardShimmer from "./CardShimmer";
import { loadStripe } from "@stripe/stripe-js";
import notfilled from "../../assets/fillcheck (2).svg";
import { FaCircleCheck } from "react-icons/fa6";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { setDefaultPrice } from "../../redux-store/subscription/subscriptionSlice";
import BasePlan from "./BasePlan";
import UpgradePlan from "./UpgradePlan";

const SubscriptionMain = ({ setSidebarOpen }) => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const texts = translations[selectedLanguage].headings;
  const textsExtra = translations[selectedLanguage].subscriptionTexts;
  const buttons = translations[selectedLanguage].buttons;
  const [paymentForm, setPaymentForm] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [cancelId, setCancelId] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);
  const [clientId, setClientId] = useState(null);
  const [setUpIntent, setSetupIntent] = useState(null);
  const [subscriptionIdSetup, setSubscriptionIdSetup] = useState(null);
  const [duration, setDuration] = useState(null);
  const [stripeId, setStripeId] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardHolderName: "",
    billingAddress: "",
    addressLinetwo: "",
    city: "",
    zip: "",
    state: "",
    country: "USA",
  });

  const getOrdinalSuffix = (day) => {
    const suffix = ["th", "st", "nd", "rd"];
    const modulo10 = day % 10;
    const modulo100 = day % 100;

    if (modulo10 === 1 && modulo100 !== 11) return suffix[1];
    if (modulo10 === 2 && modulo100 !== 12) return suffix[2];
    if (modulo10 === 3 && modulo100 !== 13) return suffix[3];
    return suffix[0];
  };

  // Function to format date as "23rd Dec 2024"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Get the token from localStorage
        const token = localStorage.getItem("token");

        if (!token) {
          console.log("Token not found in localStorage");
          setLoading(false);
          return;
        }

        // Get the base URL from .env
        const baseURL = process.env.REACT_APP_API_BASE_URL; // Assuming your base URL is stored in .env as NEXT_PUBLIC_BASE_URL

        // Make the API call to get user details
        const response = await axios.get(`/api/v1/user/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          baseURL, // Set the base URL for Axios
        });

        // Check if the subscription data exists and set it in the state
        if (response.data.content && response.data.content.subscription) {
          setSubscriptionData(response.data.content.subscription.plan);
          setDuration(response.data.content.subscription);
          setCancelId(response.data.content.subscription.stripe_id);
        } else {
          console.log("No subscription data found");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);
  useEffect(() => {
    const fetchSetupIntent = async () => {
      try {
        const token = localStorage.getItem("token"); // Get token from localStorage

        // Ensure token is available before making the request
        if (!token) {
          console.error("No token found in localStorage");
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/stripe/setup_intents`,
          {
            payment_method_types: "card",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
          }
        );
        setSetupIntent(response.data.content.setup_intent_id);
        setClientId(response.data.content.stripe_user_id);
        setClientSecret(response.data.content.client_secret); // Store the client secret
      } catch (error) {
        console.error("Error fetching setup intent:", error);
      }
    };

    fetchSetupIntent();
  }, [paymentAmount]);

  const selectedWebProductId = useSelector(
    (state) => state.subscription.selectedWebProductId
  );

  const defaultPrice = useSelector((state) => state.subscription.defaultPrice);

  console.log(selectedWebProductId, "selectedWebProductId");
  console.log(defaultPrice, "defaultPrice");
  console.log(formData, "selectedPrice");

  const toggleFormPayment = () => {
    setPaymentForm(!paymentForm);
  };

  const closeModal = () => {
    setPaymentForm(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFormSubmit = async () => {
    console.log("Form data:", formData);

    closeModal(); // Close payment form modal
    setSubmitModal(true); // Open submit modal
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const apiUrl = token
          ? `${process.env.REACT_APP_API_BASE_URL}/api/v1/subsription/list?limit=10&offset=1`
          : `https://bocaenboca.app/backend/api/v1/subsription/list-witoutlogin?limit=10&offset=1`;

        const headers = token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {};

        const response = await axios.get(apiUrl, { headers });
        setSubscriptions(response.data.content.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (!selectedWebProductId) return; // Don't make the API call if no product is selected

    const fetchProductDetails = async () => {
      setDefaultLoading(true);
      try {
        const token = localStorage.getItem("token"); // Get token from local storage
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/stripe/productDetail/${selectedWebProductId}`;

        const headers = token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {};

        const response = await axios.get(apiUrl, { headers });
        dispatch(setDefaultPrice(response.data.content.default_price));
      } catch (err) {
        setError(err.message);
        setDefaultLoading(false);
      } finally {
        setDefaultLoading(false);
      }
    };

    fetchProductDetails();
  }, [selectedWebProductId]);

  const handleSubscriptionConfirm = async () => {
    setDefaultLoading(true);
    const baseURL = process.env.REACT_APP_API_BASE_URL || ""; // Use your environment variable for the base URL
    const token = localStorage.getItem("token"); // Get the token from localStorage

    if (!token) {
      alert("User is not authenticated. Please log in.");
      setDefaultLoading(false);
      return;
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const subscriptionConfirmURL = `${baseURL}/api/v1/admin/stripe/subscriptionConfirm`;
    const subscriptionStoreURL = `${baseURL}/api/v1/subscription/store`;
    const priceId = defaultPrice; // Replace with the actual price ID

    try {
      // First API call to confirm subscription
      const confirmResponse = await axios.post(
        subscriptionConfirmURL,
        {
          price_id: priceId,
          setup_intent_id: setUpIntent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Subscription confirmed:", confirmResponse.data);

      setStripeId(confirmResponse.data.content.id);

      const storeResponse = await axios.post(
        subscriptionStoreURL,
        {
          subscription_id: subscriptionIdSetup,
          stripe_id: stripeId,
          stripe_status: "",
          stripe_price: selectedPrice,
          payment_method: "stripe",
          platform: "web",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Time-Zone": timeZone,
          },
        }
      );

      setDefaultLoading(false);
      closeModal();
    } catch (error) {
      console.error("Error confirming or storing subscription:", error);
      alert(error?.response?.data?.message || "Something went wrong!");
      setDefaultLoading(false);
      closeModal();
    }
  };
  if (loading) {
    return (
      <>
        <CardShimmer />
        <CardShimmer />
      </>
    );
  }
  return (
    <>
      <div className="scrollbar-hide w-auto paddingy mobile-top p-3 ">
        <div className="flex items-center justify-center mb-3 w-full relative bg-white sticky-top  ">
          <div className="absolute left-0">
            <img
              src={mobilebarsvg}
              alt="logo"
              className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
              onClick={() => setSidebarOpen(true)}
            />
          </div>
          <div className="flex justify-center w-full">
            <img src={bblogo} className="h-8 hidelarge" />
          </div>
        </div>

        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {texts.subscription}
        </h1>
        <hr />
        {loading ? (
          <>
            <CardShimmer />
            <CardShimmer />
          </>
        ) : (
          <>
            {subscriptionData ? (
              <>
                {subscriptionData.price === "9.99" ? (
                  <>
                    <BasePlan
                      textsExtra={textsExtra}
                      cancelId={cancelId}
                      subscriptionData={subscriptionData}
                      formatDate={formatDate}
                      duration={duration}
                    />
                    <UpgradePlan
                      textsExtra={textsExtra}
                      subscriptionData={subscriptionData}
                    />
                  </>
                ) : (
                  <>
                    <UpgradePlan
                      textsExtra={textsExtra}
                      subscriptionData={subscriptionData}
                    />
                    <BasePlan
                      textsExtra={textsExtra}
                      cancelId={cancelId}
                      subscriptionData={subscriptionData}
                      formatDate={formatDate}
                      duration={duration}
                    />
                  </>
                )}
              </>
            ) : (
              <SubscriptionCard
                subscriptions={subscriptions}
                setSelectedPrice={setSelectedPrice}
                textsExtra={textsExtra}
                buttons={buttons}
                toggleFormPayment={toggleFormPayment}
                setPaymentAmount={setPaymentAmount}
                setSubscriptionIdSetup={setSubscriptionIdSetup}
              />
            )}
          </>
        )}
      </div>

      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentModal
            paymentForm={paymentForm}
            selectedPrice={selectedPrice}
            defaultLoading={defaultLoading}
            closeModal={closeModal}
            textsExtra={textsExtra}
            formData={formData}
            handleInputChange={handleInputChange}
            handleFormSubmit={handleFormSubmit}
            setPaymentAmount={setPaymentAmount}
            clientId={clientId}
            clientSecret={clientSecret}
            setUpIntent={setUpIntent}
            handleSubscriptionConfirm={handleSubscriptionConfirm}
            card={card}
          />

          <SubmitModal
            submitModal={submitModal}
            setSubmitModal={setSubmitModal}
            textsExtra={textsExtra}
            BigLogo={BigLogo}
          />
        </Elements>
      )}
    </>
  );
};

export default SubscriptionMain;
