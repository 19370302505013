import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import RoleSelectionModal from "../components/mini-components/RoleSelectionModal";
import { Toaster, toast } from "sonner";
import Loading from "../components/loading/Loading";

const UserDetailsSocial = () => {
  const navigate = useNavigate();
  const { firstName, email, uniqueCode, platform, "*": rest } = useParams();

  const lastName = decodeURIComponent(rest || "");

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    const handleUserRegistration = async () => {
      setLoading(true);
      try {
        const checkUserResponse = await axios.post(
          `${BASE_URL}/api/v1/customer/auth/social-login`,
          { email, unique_id: uniqueCode, fcm_token: "" }
        );

        if (checkUserResponse.status === 200) {
          const { token } = checkUserResponse.data.content;
          localStorage.setItem("token", token);
          navigate("/");
        } else {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error("Error during user check:", error);
        toast("Error checking user existence.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } finally {
        setLoading(false);
      }
    };

    handleUserRegistration();
  }, [firstName, lastName, email, uniqueCode, platform, BASE_URL, navigate]);

  const handleRoleSelect = async (role) => {
    setSelectedRole(role);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const requestBody = {
      first_name: decodeURIComponent(firstName),
      last_name: lastName,
      email: decodeURIComponent(email),
      unique_id: uniqueCode,
      medium: platform,
      type: role,
      fcm_token: "",
      country_id: "233",
      state_id: String(selectedStateId),
      city_id: String(selectedCityId),
    };

    try {
      const apiResponse = await axios.post(
        `${BASE_URL}/api/v1/customer/auth/social-registration`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Time-Zone": userTimeZone,
          },
        }
      );

      const { token } = apiResponse.data.content;
      localStorage.setItem("token", token);
      navigate("/");
      toast("Registration successful!", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } catch (error) {
      console.error("Error during registration:", error);
      toast("Registration failed. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    } finally {
      setIsModalVisible(false);
    }
  };

  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center h-screen">
      {loading && <div></div>}
      <RoleSelectionModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSelectRole={handleRoleSelect}
        setSelectedStateId={setSelectedStateId}
        setSelectedCityId={setSelectedCityId}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
      />
      <Toaster position="top-right" />
      <div className="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default UserDetailsSocial;
