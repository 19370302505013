import React from "react";

const Refund = () => {
  return (
    <div className="max-w-4xl mx-auto  bg-white text-xs ">
      {/* About Us Section */}
      <div className="container mx-auto ">
        <h1 className="text-md font-bold mb-4">About Us</h1>
        <p className="mb-4">
          Boca En Boca™ is a community-driven hiring app that aims to
          revolutionize the job search process by facilitating direct
          connections between employers and job seekers. The app provides
          seamless job search functionality coupled with a direct text messaging
          tool, enabling authentic conversations that lead to better hiring
          decisions.
        </p>
        <p className="mb-4">
          The current hiring process relies too heavily on resume screening and
          applications that fail to capture a candidate's full potential. This
          overwhelms employers with unqualified candidates and frustrates job
          seekers. Boca En Boca addresses this by prioritizing meaningful
          dialogues and interactions.
        </p>
        <p className="mb-4">
          We offer a comprehensive solution that combines seamless job search
          functionalities with a direct text messaging tool. Job seekers can
          browse through these listings and communicate directly with the click
          of a single button. We also make it easy to set up phone, video
          conference, and in-person interviews within our direct text messaging
          tool.
        </p>
        <p className="mb-4">
          Our direct text messaging tool allows employers and job seekers to
          engage in meaningful discussions, enabling both parties to assess
          mutual compatibility and clarify any questions or concerns. This
          approach promotes transparency and helps to ensure that the right
          candidate is matched with the right job.
        </p>
        <p className="mb-4">
          Imagine a scenario where a job seeker has questions about the
          company's work culture or a specific aspect of the role. With Boca En
          Boca, they can simply reach out to the employer directly through our
          messaging system and get immediate answers. Similarly, employers can
          evaluate a candidate's communication skills, cultural fit, and
          enthusiasm through these exchanges.
        </p>
        <p className="mb-4">
          By providing the tools to have these genuine interactions, we aim to
          reduce the frustration and inefficiency that often accompanies the
          current broken hiring process. We understand that finding the perfect
          fit goes beyond a resume or application and that is why we provide a
          platform that encourages open dialogue and fosters connections based
          on more than just qualifications in writing.
        </p>

        <h2 className="text-md font-bold mt-6 mb-4">Cancellation Policy</h2>
        <p className="mb-4">
          You can cancel your free or paid user account at any time. After the
          account is cancelled, you can always reactivate it using your existing
          username and password.
        </p>

        <h2 className="text-md font-bold mt-6 mb-4">Refund Policy</h2>
        <p className="mb-4">
          Boca En Boca does not offer refunds for paid subscriptions. All
          subscriptions are billed annually in advance. You may switch
          subscription plans at any time, including switching to a free account,
          but any changes will take effect at the end of your current
          subscription term.
        </p>

        <p className="mb-4">
          If you have questions about the Cancellation & Refund Policy, please
          contact us at
          <a
            href="mailto:support@bocaenboca.com"
            className="text-orange-500 cursor-pointer ml-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@bocaenboca.com
          </a>
        </p>

        <h2 className="text-md font-bold mt-6 mb-4">Legal</h2>
        <p className="mb-4">
          Boca En Boca™ is a trademark owned by The Peddler Group, Inc., a
          corporation formed in Texas, established on December 18, 2018.
        </p>
      </div>
    </div>
  );
};

export default Refund;
