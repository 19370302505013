import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-tooltip/dist/react-tooltip.css";
import store, { persistor } from "./redux-store/store";

const rootElement = document.getElementById("root");

// Create App container component
const AppContainer = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {process.env.NODE_ENV === "development" ? (
        <React.StrictMode>
          <App />
        </React.StrictMode>
      ) : (
        <App />
      )}
    </PersistGate>
  </Provider>
);

// Use `hydrate` for SSR optimization
if (rootElement.hasChildNodes()) {
  hydrate(<AppContainer />, rootElement);
} else {
  render(<AppContainer />, rootElement);
}
